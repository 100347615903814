import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

// Styling
import colors from "../../styles/colors"
import { mq } from "../../styles/utils/mq"

// Components
import DownloadIcon from "../../icons/DownloadIcon"

interface IDownload {
  fileURL?: string
}

const DownloadButton = ({ fileURL }: IDownload) => (
  <span>
    {fileURL !== undefined && fileURL !== null ? (
      <DownloadBox href={fileURL} download>
        <StyledDownloadIcon color={colors.black} />
        <DownloadBtn>Download</DownloadBtn>
      </DownloadBox>
    ) : (
      <StaticQuery
        query={graphql`
          query retrievePDP {
            file(relativePath: { eq: "PDP-template-for-tribe-leads.key" }) {
              publicURL
            }
          }
        `}
        render={data => (
          <DownloadBox href={data.file.publicURL} download>
            <StyledDownloadIcon color={colors.black} />
            <DownloadBtn>Download</DownloadBtn>
          </DownloadBox>
        )}
      />
    )}
  </span>
)

export default DownloadButton

const DownloadBox = styled.a`
  display: flex;
`

const StyledDownloadIcon = styled(DownloadIcon)`
  height: 25px;
  width: 25px;
  vertical-align: middle;
  margin: auto 10px;
  transition: 0.3s;
`

const DownloadBtn = styled.div`
  vertical-align: middle;
  margin-left: 10px;
  text-align: center;
  padding: 5px 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: calc(100% - 55px);
  cursor: pointer;
  background-color: ${colors.accent1};
  color: ${colors.white};
  border: 2px solid ${colors.accent1};
  max-width: 400px;

  ${mq.from.M`
    min-width: 110px;
    max-width: 300px;
  `}
`
