import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

// Components
import PageLayout from "../components/PageLayout"
import { ProfileTitle } from "../components/FunctionsList"
import DownloadButton from "../components/atoms/DownloadButton"

// Styling
import colors from "../styles/colors"
import { mq } from "../styles/utils/mq"

const DownloadsPage = (data: any) => {
  const evaluationTribes = data.data.allPrismicTribeEvaluation.edges

  return (
    <PageLayout seoTitle={"Downloads"}>
      <DownloadBlock>
        <InformationBlock>
          <ProfileTitle>Personal Development Plan (PDP)</ProfileTitle>
          <Description>
            A simple template for setting your personal goals for developing
            both
            <Special> skills</Special> and <Special>qualities</Special>.
          </Description>
        </InformationBlock>
        <InformationBlock>
          <AlignRight>
            <PreviewImage
              src="https://images.pexels.com/photos/590037/pexels-photo-590037.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
              alt="placeholder"
            />
            <DownloadButton />
          </AlignRight>
        </InformationBlock>
      </DownloadBlock>
      <DownloadBlock>
        <InformationBlock>
          <ProfileTitle>Evaluation Template</ProfileTitle>
          <Description>
            A simple template for setting your personal goals for developing
            both
            <Special> skills</Special> and <Special>qualities</Special>.
          </Description>
          <DownloadTable>
            <tr>
              <th>Tribe</th>
              <th>Level</th>
              <th></th>
            </tr>
            <tbody>
              {evaluationTribes.map((level: any) => {
                level = level.node.data

                return (
                  <>
                    {level.body.map((evaluation: any, index: number) => {
                      evaluation = evaluation.primary

                      return (
                        <tr className={"tribe-row"} key={index}>
                          {index === 0 ? (
                            <td>{level.tribe.text}</td>
                          ) : (
                            <td></td>
                          )}
                          <td>Evaluation {evaluation.level.text}</td>
                          <td>
                            <DownloadButton fileURL={evaluation.document.url} />
                          </td>
                        </tr>
                      )
                    })}
                  </>
                )
              })}
            </tbody>
          </DownloadTable>
        </InformationBlock>
        <InformationBlock>
          <AlignRight>
            <PreviewImage
              src="https://images.pexels.com/photos/590037/pexels-photo-590037.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
              alt="placeholder"
            />
          </AlignRight>
        </InformationBlock>
      </DownloadBlock>
    </PageLayout>
  )
}

export const query = graphql`
  query {
    allPrismicTribeEvaluation {
      edges {
        node {
          data {
            body {
              primary {
                level {
                  text
                }
                document {
                  url
                }
              }
            }
            tribe {
              text
            }
          }
        }
      }
    }
  }
`

export default DownloadsPage

const DownloadBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  ${mq.from.M`
    flex-direction: row;
  `}

  &:not(:last-of-type) {
    padding-bottom: 25px;
    border-bottom: 1px solid ${colors.black};
  }
`

const AlignRight = styled.div`
  ${mq.from.M`
    float: right;
  `}
`

const Special = styled.span``

const Description = styled.p``

const InformationBlock = styled.div`
  display: block;
  flex: 50%;

  ${ProfileTitle} {
    font-size: 2rem;
    line-height: 1;
    font-weight: bold;
    display: block;
  }

  ${Description} {
    line-height: 1.5;
    letter-spacing: 1px;

    ${Special} {
      color: ${colors.accent1};
    }
  }
`

const DownloadTable = styled.table`
  width: calc(100% - 55px);
  border-collapse: collapse;
  margin: 50px 0;
  font-size: 20px;

  th {
    text-align: left;
  }

  td {
    padding: 10px 0;
  }

  ${mq.from.M`
    width: 100%;
  `}

  tr {
    border-bottom: 1px solid ${colors.black};
  }

  tr:last-child {
    border: none;
  }
`

const PreviewImage = styled.img`
  height: 175px;
  width: 100%;
  margin: 20px 0;
  display: block;
  box-shadow: 2px 4px 10px;
  margin-bottom: 20px;
  object-fit: cover;
  object-position: center;
  max-width: 455px;

  ${mq.from.M`
    width: 300px;
    margin-left: 55px;
  `}
`
